.ant-select {
    margin: 12px 10px 0 0;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 30px;
    line-height: 30px;
    padding: 0 30px 0 10px;
    border-radius: 14px;
    background-image: linear-gradient(#f6f4f8, #fde9f0);
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
    &::after {
        content: '';
        display: inline-block;
        height: 7px;
        width: 11px;
        background: url('../images/downArrow.png') no-repeat 100% / contain;
        position: absolute;
        right: 0;
        top: 11px;
    }
}

.spin-bg {
    position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    z-index: 100;
    .ant-spin {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .ant-spin-lg .ant-spin-dot {
        font-size: 50px;
    }
}

.pick_checkbox {
    font-size: 13px;
    line-height: 18px;
    .ant-checkbox-inner {
        border-radius: 50%;
    }
    .ant-checkbox-checked::after {
        border: none;
    }
}

.ant-picker:hover,
.ant-picker-focused {
    border: 0;
    box-shadow: 0;
}
