@import './global.scss';

.table {
    width: 100%;
    padding-bottom: 100%;
    background: url('../images/table_bg.png') no-repeat 100% / cover;
    position: relative;
}
.tableTwo{
    @extend .table;
    background: url('../images/tableTwo_bg.png') no-repeat 100% / cover; 
}
.amount {
    color: $pink;
    font-size: 13px;
    border: 1px solid $pink;
    border-radius: 4px;
    display: inline-block;
    padding: 3px 3px 2px 2px;
    margin-bottom: 5px;
    &.winner {
        color: $gold;
        border: 1px solid $gold;
    }
}
.centerConWarp {
    position: absolute;
    box-sizing: border-box;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(180deg, #211c1e 2%, #100b10 100%);
    box-shadow: -2px -2px 0px 0px #293036, 2px 2px 0px 0px #0e0d10;
    border-radius: 8px;
    border: 2px solid;
    border-image: linear-gradient(
            180deg,
            rgba(112, 123, 129, 1),
            rgba(23, 31, 42, 1)
        )
        2 2;
    padding: 5px;
    overflow: hidden;
    max-width: 60%;

    &.logo {
        background: url('../images/logo.png') 100% / contain no-repeat;
        top: 52%;
        height: 20%;
        width: 20%;
        border: 0;
        box-shadow: none;
    }
}
.logoNone{
    display: none;
}
.centerCon {
    font-size: 28px;
    color: #fff;
}

.smallTxt {
    .first {
        font-size: 12px !important;
    }
    .second {
        bottom: 15% !important;
    }
    .centerCon {
        font-size: 14px !important;
        .highlight {
            color: $gold;
            font-size: 16px;
        }
    }
}

.item {
    height: 20%;
    width: 20%;
    position: absolute;
    overflow: visible;
    .winner {
        width: 100%;
        position: absolute;
        z-index: 5;
        top: -33%;
    }
    .winneryang{
        width: 18px;
        height: 36px;
        position: absolute;
        z-index: 5;
        top:25px;
        right: -50px;
        transform:rotate(180deg);
    }
    .winneryin{
        width: 18px;
        height: 36px;
        position: absolute;
        z-index: 5;
        top:-33px;
        left: -53px;
    }
    
    &.othersPickTwoyin{
        background: url('../images/yinOther.png') no-repeat 100% / cover;
    }
    &.othersPickTwoyang{
        background: url('../images/yangOther.png') no-repeat 100% / cover;
    }
    &.othersPick {
        background: url('../images/table_others.png') no-repeat 100% / cover;

        .first {
            font-size: 24px;
            color: $gold;
            position: absolute;
            top: 10%;
            text-align: center;
            display: inline-block;
            width: 100%;
            padding-right: 2%;
            z-index: 10;
        }
        .second {
            @extend .first;
            top: auto;
            bottom: 11%;
        }
    }
    &.myPickTwoyin{
        background: url('../images/yinSelf.png') no-repeat 100% / cover;
    }
    &.myPickTwoyang{
        background: url('../images/yangSelf.png') no-repeat 100% / cover;
    }
    &.myPick {
        @extend .othersPick;
        background: url('../images/myPick.png') no-repeat 100% / cover;
    }
}

.jin {
    @extend .item;
    background: url('../images/jin.png') no-repeat 100% / cover;
    top: -2%;
    left: 40%;
}

.mu {
    @extend .item;
    background: url('../images/mu.png') no-repeat 100% / cover;
    top: 29%;
    right: 0;
}
.shui {
    @extend .item;
    background: url('../images/shui.png') no-repeat 100% / cover;
    bottom: 5%;
    right: 15%;
}
.huo {
    @extend .item;
    background: url('../images/huo.png') no-repeat 100% / cover;
    bottom: 5%;
    left: 15%;
}
.tu {
    @extend .item;
    background: url('../images/tu.png') no-repeat 100% / cover;
    top: 29%;
    left: 0;
}

.yin{
    @extend .item; 
    background: url('../images/yin.png') no-repeat 100% / cover;
    top: 58%;
    left: 35%;
    width: 56px;
    height: 56px;
}
.yang{
    @extend .item; 
    background: url('../images/yang.png') no-repeat 100% / cover;
    top: 27%;
    right: 34%;
    width: 56px;
    height: 56px;
}
.smallTxt{
    .yin{
        width: 28px;
        height: 28px;
    }
    .yang{
        width: 28px;
        height: 28px;
    }
}
