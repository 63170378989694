@import './global.scss';

.home {
    min-height: 100vh;
    padding: 0 5% 18px 5%;
    background: $black url('../images/bg.png') no-repeat top center;
    position: relative;
}

.header {
    height: 54px;
    background: #171522;
    width: calc(100vw - 2px);
    margin-left: -5%;
    padding-left: 5%;
    margin-bottom: 14px;
    max-width: 418px;
    .dropdown {
        background: red;
    }
    .avatar {
        float: right;
        margin: 11px 5% 0 0;
        height: 32px;
        width: 32px;
        background: url('../images/profile.png') 100% / contain;
    }
    .report {
        color: $white;
        height: 54px;
        line-height: 54px;
        font-size: 14px;
        margin-left: 10px;
    }
    .lang {
        float: left;
        margin: 17px 0 0 0;
        height: 22px;
        width: 22px;
        background: url('../images/language.png') 100% / contain;
    }
}

.mainTitle {
    overflow: hidden;
    margin: 15px 0;
    p {
        font-size: 16px;
        color: $white;
        float: left;
    }
    .countdown {
        float: right;
        color: $gold;
        font-size: 11px;
        margin-top: 2px;
    }
    .hitPlayers {
        color: $gold;
    }
    .onTheListStatus {
        min-height: 34px;
        .img {
            height: 34px;
            width: 34px;
            background: url('../images/status_bg.png') no-repeat 100% / contain;
            margin: 0 0 -10px 8px;
            display: inline-block;
            text-align: center;
            color: $white;
            position: relative;
            span {
                display: inline-block;
                height: 68px;
                line-height: 68px;
                width: 68px;
                position: absolute;
                left: -50%;
                top: -48%;
                font-size: 16px;
                transform: scale(0.5, 0.5);
            }
            &.active {
                background: url('../images/status_bg_on.png') no-repeat 100% /
                    contain;
                color: $gold;
            }
        }
    }
    .myGold {
        color: $gold;
        font-size: 14px;
        float: right;
        display: block;
        margin-top: 5px;
        &::before {
            content: '';
            height: 26px;
            width: 26px;
            background: url('../images/gold.png') no-repeat 100% / cover;
            display: inline-block;
            margin-right: 4px;
            margin-bottom: -9px;
        }
    }
}

.infoBoxTabs {
    span {
        width: 50%;
        height: 36px;
        line-height: 36px;
        text-align: center;
        display: inline-block;
        background: #212931;
        color: $white;
        font-size: 13px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        &.active {
            background: $pink;
        }
    }
}

.main {
    padding: 10px 0 20px 0;
    position: relative;
    .hammer {
        height: 46px;
        width: 43px;
        position: absolute;
        right: 0;
        top: 50px;
        z-index: 10;
    }
}

.changeTable {
    text-align: center;
    position: relative;
    margin-bottom: 20px;

    p {
        color: #7d6a39;
        font-size: 13px;
        display: inline-block;
        margin: 0 67px;
        span {
            color: $gold;
            padding: 0 3px;
        }
    }
    button {
        position: absolute;
        right: 0;
        top: -3px;
        background: url('../images/home_btn_bg.png') 100% / contain;
        font-size: 13px;
        padding: 5px;
        color: $gold;
        width: 67px;
        height: 26px;
        &.newTable {
            left: 0;
            padding: 5px 10px;
            right: unset;
        }
        &.disabled {
            background: #ccc;
            color: $black;
        }
    }
}

.rate {
    font-size: 12px;
    color: $white;
    margin-left: 10px;
    display: inline-block;
    span {
        color: $pink;
    }
}

.buttons {
    margin-bottom: 22px;
    button {
        width: 72px;
        height: 29px;
        border: 0;
        color: $gold;
        font-size: 14px;
        background: url('../images/btn_bg.png') no-repeat 100% / contain;
    }

    .right {
        float: right;
    }
}

.rules {
    margin-bottom: 16px;
    .title {
        font-size: 12px;
        color: $gold;
        max-width: 40%;
        &::before {
            content: '';
            height: 16px;
            width: 16px;
            background: url('../images/hint.png') no-repeat 100% / cover;
            display: inline-block;
            margin-right: 8px;
            margin-bottom: -3.5px;
        }
    }
    .countdown {
        float: right;
        margin-top: 1px;
    }
}

.shareHint {
    color: $pink;
    font-size: 13px;
    margin: -10px 0 10px 0;
}

.brocadeBagIcon {
    width: 36px;
    height: 39px;
    display: block;
    background: url('../images/brocadeBag.png') 100% / contain;
    float: right;
    margin-top: -2px;
    &.active {
        background: url('../images/brocadeBag_active.png') 100% / contain;
    }
}

.brocadeBag {
    .bg {
        background: rgba(0, 0, 0, 0.8);
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        padding-top: calc(50vh - 80px);
        z-index: 11;
    }
    .box {
        background: $white;
        padding: 16px;
        overflow: hidden;
        border-radius: 8px;
        width: 90%;
        margin: 0 auto;
        p {
            color: $black;
            clear: both;
            font-family: PingFangSC-Regular, PingFang SC;
            width: 100%;
        }
        span {
            font-family: PingFangSC-Regular, PingFang SC;
        }
    }
    .title {
        font-size: 15px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        text-align: center;
        margin-bottom: 18px;
    }
    .item {
        font-size: 14px;
        margin-bottom: 20px;
        .content {
            font-size: 18px;
            font-family: DINAlternate-Bold, DINAlternate;
            font-weight: bold;
            float: right;
            .hightlight {
                color: $pink;
                font-size: 15.6px;
            }
        }
        .hash {
            font-size: 14px;
            font-family: PingFangTC-Regular, PingFangTC;
            font-weight: 400;
        }
    }
    button {
        background: linear-gradient(#fc7dcd, #f7479e);
        width: 100%;
        height: 48px;
        line-height: 48px;
        color: $white;
        margin-top: 10px;
        border-radius: 4px;
    }
}

.trophies {
    color: #fff;
    background: linear-gradient(180deg, #211c1e 2%, #100b10 100%);
    box-shadow: -3px -3px 0px 0px #293036, 4px 4px 0px 0px #0e0d10;
    border-radius: 8px;
    border: 3px solid;
    border-image: linear-gradient(
            180deg,
            rgba(112, 123, 129, 1),
            rgba(23, 31, 42, 1)
        )
        3 3;
    margin-top: 24px;
    position: relative;
    padding: 30px 0 20px 0;
    .title {
        height: 35px;
        line-height: 40px;
        width: 86px;
        text-align: center;
        background: url('../images/trophy_title.png') 100% center / contain;
        font-size: 14px;
        color: #e5bc69;
        position: absolute;
        top: -16px;
        left: calc(50% - 43px);
    }
    .total {
        height: 60px;
        line-height: 66px;
        width: 282px;
        background: url('../images/trophy_bg.png') 100% center / contain;
        margin: 0 auto;
        position: relative;
        padding-left: 40px;
        font-size: 12px;
        &::before {
            height: 38px;
            width: 38px;
            content: '';
            display: block;
            background: url('../images/trophy_total.png') 100% center / contain;
            position: absolute;
            top: 12px;
            left: -8px;
        }
        span {
            display: inline-block;
            width: 33%;
            overflow: hidden;
            &:nth-child(2n) {
                width: 25%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            &:last-child {
                width: 40%;
                text-align: right;
            }
        }
    }
    .single {
        @extend .total;
        &::before {
            background: url('../images/trophy_single.png') 100% center / contain;
        }
    }
}
