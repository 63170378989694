@import "./global.scss";

.all {
  min-height: 100vh;
  background-color: #493024;
  position: relative;
}
.header {
  background-color: #1b1a1e;
  height: 53px;
  padding: 7px 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  width: 116px;
  height: 39px;
}
.home {
  width: 24px;
  height: 24px;
}
.black {
  background-color: black;
  padding-top: 11px;
}
.income {
  background: url("../images/jianghu_bg.png") no-repeat;
  background-size: 100% 100%;
  width: 91%;
  margin: auto;
  padding-top: 39px;
  padding-bottom: 10px;
  text-align: center;
}

.promoteIV {
  margin-bottom: 23px;
  font-size: 22px !important;
}
.distribute {
  background-color: #1c1412;
  width: 86%;
  border-radius: 8px;
  margin: auto;
  display: flex;
  padding: 10px 21px;
  justify-content: space-between;
}
.distributeI div:nth-child(1),
.promote {
  font-size: 13px;
  color: white;
  margin-bottom: 15px;
}
.distributeI div:nth-child(2),
.promoteIV {
  font-size: 19px;
  color: #f7479e;
  font-weight: bold;
}
.main {
  position: relative;
}
.blank1 {
  width: 100%;
  height: 8px;
  background-color: black;
  position: absolute;
}
.tab {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: white;
  border: 1px solid #825840;
  border-radius: 8px;
  padding: 6px 4.5%;
  margin-bottom: 16px;
  position: relative;
  background-color: #483024;
}
.selected,
.noSelected {
  width: 32%;
  height: 48px;
  text-align: center;
  line-height: 48px;
  // background-size: 100% 100%;
}
.selected {
  background: url("../images/jianghu_selected.png") no-repeat 100% / contain;
}
.noSelected {
  background: url("../images/jianghu_default.png") no-repeat 100% / contain;
}
.task {
  width: 91%;
  margin: auto;
  background-color: #c7bfae;
  border: 1px solid #906e4c;
  border-radius: 8px;
  padding: 8px;
}

.receiveAll {
  background: url("../images/jianghu_receiveAll.png") no-repeat 100% / contain;
  width: 85px;
  height: 25px;
  line-height: 24px;
  color: white;
  font-size: 12px;
  text-align: center;
  margin-bottom: 9px;
  margin-left: auto;
}
.taskItem {
  background-color: #ede5d4;
  border: 1px solid #b79e7d;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 8px;
  margin-bottom: 8px;
}
.taskDetail {
  display: flex;
  width: 67%;
  img {
    width: 37px;
    height: 37px;
    margin-right: 8px;
  }
}

.taskReward div:nth-child(1) {
  font-size: 13px;
  color: #4d3921;
  font-weight: bold;
}
.taskReward div:nth-child(2) {
  font-size: 12px;
  color: #f7479e;
  margin-top: 8px;
}
.receive,
.received {
  width: 75px;
  height: 39px;
  line-height: 39px;
  text-align: center;
  color: white;
  font-size: 15px;
}
.receive {
  background: url("../images/jianghu_receive.png") no-repeat 100% / contain;
}
.received {
  background: url("../images/jianghu_received.png") no-repeat 100% / contain;
}
.taskProgress {
  width: 93px;
  div {
    font-size: 10px;
    color: #4d3921;
    text-align: center;
  }
}
.progressData {
  margin-left: 2px;
  color: #f7479e;
}
.blank2 {
  height: 34px;
}
.invite {
  width: 91%;
  margin: auto;
  position: relative;
}
.myInvite {
  position: absolute;
  width: 102px;
  height: 36px;
  padding: 4px;
  top: -20px;
  font-size: 15px;
  background-color: #745230;
  div {
    background-color: #f7df7c;
    border: 3px solid #efa042;
    text-align: center;
    padding: 3px 0;
  }
}

.inviteDetail {
  background-color: #745230;
  padding: 8px 8px 16px 8px;
  border-radius: 0 8px 8px 8px;
}
.inviteData {
  background-color: #ede5d4;
  border: 1px solid #b79e7d;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
}
.inviteP {
  text-align: center;
  font-size: 14px;
}
.inviteP div:nth-child(1) {
  color: #4d3921;
}
.inviteP div:nth-child(2) {
  color: #f7479e;
  font-weight: bold;
  margin-top: 12px;
}
.blank3 {
  height: 100px;
}
.inviteFriend {
  width: 420px;
  height: 76px;
  background-color: #493024;
  position: fixed;
  bottom: 0;
}
.inviteFriend div {
  background-image: linear-gradient(#fc7dcd, #f7479e);
  width: 382px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: white;
  font-size: 16px;
  font-weight: bold;
  margin: auto;
}
.receiveModal {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  min-height: 100vh;
  width: 420px;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
.receiveContent {
  background-color: white;
  width: 91%;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  font-size: 14px;
}
.receiveContent div {
  word-wrap: break-word;
}
.receiveContent div:nth-child(1) {
  font-size: 15px;
  font-weight: bold;
}
.receiveContent div:nth-child(2) {
  margin-top: 40px;
  margin-bottom: 45px;
}
.receiveContent div:nth-child(3) {
  color: #f653a2;
}
@media only screen and (max-width: 510px) {
  .inviteFriend {
    width: 100%;
  }
  .inviteFriend div {
    width: 91%;
    margin: auto;
  }
  .receiveModal {
    width: 100%;
  }
}
