@import './global.scss';

.profile {
    background: $grey;
    min-height: 100vh;
}

.header {
    height: 44px;
    line-height: 46px;
    text-align: center;
    position: relative;
    font-size: 18px;
}

.back {
    background: url('../images/back.png') no-repeat 100% / contain;
    height: 18px;
    width: 11px;
    position: absolute;
    left: 4%;
    top: 13px;
}

.container {
    background: $white;
    width: 90%;
    margin: 16px auto;
    padding: 0 10px;
    height: 88vh;
}

.info {
    height: 48px;
    line-height: 48px;
    .hint {
        color: #ccc;
        font-size: 14px;
    }
    .status {
        display: block;
        text-align: center;
        height: 34px;
        line-height: 36px;
        width: 34px;
        background: url('../images/status_bg.png') no-repeat 100% / contain;
        float: right;
        margin-top: 6px;
        span {
            display: block;
            color: $white;
            font-size: 16px;
            transform: scale(0.5, 0.5);
            width: 68px;
            margin-left: -50%;
        }
        &.active {
            background: url('../images/status_bg_on.png') no-repeat 100% /
                contain;
            color: $gold;
            span {
                transform: scale(0.8, 0.8);
            }
        }
    }
}
.pink{
    color: rgba(247, 71, 158, 1);
}
.grey{
color: #ABB0B4;
}
.size_12{
    font-size: 0.13rem;
}
.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
.item {
    height: 48px;
    line-height: 48px;
    border: 1px solid #f5f5f5;
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 0 10px;
    .title {
        font-size: 15px;
        &.gold::before {
            content: '';
            height: 24px;
            width: 24px;
            background: url('../images/gold.png') no-repeat 100% / cover;
            display: inline-block;
            margin-right: 6px;
            margin-bottom: -6px;
        }
        &.lingDan::before {
            content: '';
            height: 26px;
            width: 26px;
            background: url('../images/lingdan.png') no-repeat 100% / cover;
            display: inline-block;
            margin-right: 4px;
            margin-bottom: -8px;
        }
        &.shengwu::before {
            content: '';
            height: 26px;
            width: 26px;
            background: url('../images/shengwu.png') no-repeat 100% / cover;
            display: inline-block;
            margin-right: 4px;
            margin-bottom: -8px;
        }
    }
    .levelProcess {
        display: inline-flex;
        flex-direction: column;
        width: 5rem;
        >p{
            height: 1.6rem;
        }
        .processLay {
          display: inline-block;
          width: 100%;
          height: 0.18rem;
          background: #c2c0b7;
          position: relative;
          margin-top: 0.05rem;
          border-radius: 0.1rem;
          .process {
            background: #ac650f;
            width: 20%;
            height: 0.18rem;
            position: absolute;
            border-radius: 0.1rem;
            left: 0;
          }
        }
      }

    .content {
        font-size: 15px;
        color: $pink;
        float: right;
        position: relative;
        .submitName{
            display: inline-block;
            width: 10rem;
            text-align: right;
        }
    }
    .arrow {
        display: block;
        width: 9px;
        height: 16px;
        background: url('../images/arrow.png') no-repeat 100% / cover;
        float: right;
        margin: 16px 0 0 14px;
    }
}

.activity {
    margin: 24px 0 12px 10px;
    &::after {
        content: '';
        display: inline-block;
        height: 25px;
        width: 20px;
        background: url('../images/fire.png') no-repeat 100% / contain;
        margin: 0 0 -5px 5px;
    }
}
