@import "./global.scss";

.lingDanDetails {
  background: $grey;
  min-height: 100vh;
}

.header {
  height: 44px;
  line-height: 46px;
  text-align: center;
  position: relative;
  font-size: 18px;
  background: $white;
}

.back {
  background: url("../images/back.png") no-repeat 100% / contain;
  height: 18px;
  width: 11px;
  position: absolute;
  left: 4%;
  top: 13px;
}

.topContainer {
  background: $white;
  padding: 16px 4% 24px 4%;
}

.totalNum {
  background: linear-gradient(#fd93d8, #f95aaf);
  border-radius: 8px;
  color: $white;
  height: 74px;
  line-height: 74px;
  padding: 0 16px;
  font-size: 14px;
  .number {
    float: right;
    font-size: 22px;
  }
}

.datePicker {
  border-radius: 13px;
  width: 90px;
  margin-top: 16px;
  background: #f5f5f5;
  border: 0;
}

.list {
  padding: 16px 4%;
  li {
    background: $white;
    border-radius: 8px;
    overflow: hidden;
    height: 96px;
    padding: 0 16px;
    margin-bottom: 8px;
    .left {
      margin-top: 25px;
      height: 50px;
      line-height: 25px;
      width: 70%;
      float: left;
      font-size: 14px;
      .date {
        color: #b0b0b0;
        font-size: 12px;
      }
    }
    .right {
      height: 96px;
      line-height: 96px;
      width: 30%;
      float: right;
      text-align: right;
      color: #882fea;
    }
  }
}
