.all {
  background-color: #f7f8fa;
  height: 100vh;
  padding: 17px;
}
.last {
  margin-bottom: 16px;
  font-size: 14px;
  padding: 15px 10px;
}
.last div:nth-child(2) {
  margin-left: 10px;
  color: #f7479e;
  font-weight: bold;
}
.inviteData,
.last {
  background-color: #ede5d4;
  border: 1px solid #b79e7d;
  border-radius: 8px;
  display: flex;
}
.inviteData {
  padding: 20px 30px;
  justify-content: space-between;
}
.inviteP {
  text-align: center;
  font-size: 14px;
}
.inviteP div:nth-child(1),
.last {
  color: #4d3921;
}
.inviteP div:nth-child(2) {
  color: #f7479e;
  font-weight: bold;
  margin-top: 12px;
}
.detail {
  margin-top: 16px;
  font-size: 13px;
}
.detailHeader {
  background-color: #493024;
  display: flex;
  color: #ead1b7;
  border-radius: 8px 8px 0 0;
  padding: 8px 16px;
}
.listItem {
  display: flex;
  background-color: white;
  padding: 16px;
  font-weight: bold;
  color: #3f3f3f;
  border: 0.5px solid #f5f5f5;
}
.listItem div:nth-child(1),
.detailHeader div:nth-child(1) {
  width: 42%;
}
.listItem div:nth-child(2),
.detailHeader div:nth-child(2) {
  width: 30%;
}
.listItem div:nth-child(3),
.detailHeader div:nth-child(3) {
  width: 28%;
  text-align: right;
}
.listItem div:nth-child(3) {
  color: #f64da0;
}
.more {
  color: #f64da0;
  text-align: right;
  margin-top: 15px;
}
