@import './global.scss';

.popup {
    background: $black url('../images/bg.png') no-repeat top center;

    .box {
        width: 308px;
        height: 149px;
        margin: 0 auto;
        padding: 26px 10px 16px 10px;
        background: url('../images/entry_bg.png') no-repeat 100% / contain;
        text-align: center;
    }

    p {
        font-size: 13px;
        line-height: 18px;
        color: $white;
        margin-bottom: 16px;
    }

    button {
        background: url('../images/entry_btn.png') no-repeat 100% / contain;
        color: $pink;
        font-size: 13px;
        height: 44px;
        line-height: 42px;
        width: 110px;
        text-align: center;
    }
}

.selectPopup {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    .bg {
        background-color: rgba(0, 0, 0, 0.8);
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        z-index: 10;
    }
    .box {
        background-color: $white;
        padding: 16px;
        position: fixed;
        top: 50%;
        transform: translate(0, -50%);
        width: 90%;
        left: 5%;
        border-radius: 8px;
        z-index: 11;
        .optionsContainer {
            overflow: hidden;
        }
        .options {
            width: 47%;
            height: 48px;
            line-height: 48px;
            text-align: center;
            background: $lightPink;
            color: $pink;
            border-radius: 4px;
            margin-bottom: 8px;
            position: relative;
            float: left;
            &:nth-child(2n - 1) {
                margin-right: 6%;
            }
            &.active::after {
                content: '';
                display: inline-block;
                height: 48px;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                border: 1px solid $darkPink;
                border-radius: 4px;
            }
        }
        button {
            display: block;
            background: $pink;
            color: $white;
            width: 160px;
            height: 50px;
            margin: 10px auto 0 auto;
            border-radius: 8px;
            &.disabled {
                background: #ccc;
            }
        }
        .tokenInfo {
            height: auto;
            padding: 10px 0 6px 0;
            line-height: unset;
            .tokenName {
                font-size: 13px;
                color: $black;
                display: block;
                margin-bottom: 5px;
            }
            .tokenNumber {
                font-size: 22px;
                color: $pink;
                display: block;
            }
        }
    }
    .title {
        font-size: 15px;
        font-weight: 600;
        text-align: left;
        margin-bottom: 24px;
    }

    .hint {
        color: #909498;
        font-size: 12px;
        text-align: left;
        margin-top: 24px;
    }
}
