@import './global.scss';

.tables {
    padding-bottom: 18px;
    background: $black url('../images/bg.png') no-repeat top center;
    min-height: 100vh;
    position: relative;
}

.header {
    height: 44px;
    line-height: 46px;
    background: $white;
    text-align: center;
    span {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 50px;
        cursor: pointer;
        font-size: 13px;
        padding-left: 30px;
        &::before {
            content: '';
            background: url('../images/back.png') 100% / contain;
            height: 18px;
            width: 11px;
            top: 13px;
            left: 13px;
            position: absolute;
            display: block;
        }
    }
}
.tab{
    display: flex;
    justify-content: space-between;
    background: $white;
    height: 50px;
    line-height: 50px;
    font-size: 13px;
}
.tabLeft {
    // margin-top: -1px;
    span {
        margin-left: 16px;
        cursor: pointer;
        &.active {
            color: $pink;
            font-weight: 600;
        }
    }
}

.numbers {
    margin: 10px;
}

.container {
    overflow: hidden;
    padding: 3%;
}

.item {
    width: 48%;
    padding-top: 10px;
    float: left;
    &:nth-child(2n) {
        float: right;
    }
}
.loadingWarp {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.loadMoreBtn {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 4px;
    display: inline-block;
    margin: 0 auto;
    font-size: 14px;
    border: 1px solid #eee;
}
.modal {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    min-height: 100vh;
    width: 100%;
    max-width: 420px;
    top: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modalCon{
      position: absolute;
      bottom: 0;
    background-color: #1C1C1E;
    border-radius: 12px 12px 0 0;
    color:white;
    font-size: 17px;
    padding:17px 17px 40px 17px;
  }
  .modalTitle{
    font-size: 15px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    padding-bottom: 10px;
    border-bottom: 1px solid #29292C;
    img{
      width: 45px;
      height: 44px;
    }
  }
  .market ,.blindBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:22px 12px;
    img{
      width: 75px;
      height: 75px;
    }
  }
  .marketCon{
      width: 72%;
  }
  .OfficialSite{
      margin-top: 8px;
      font-size: 14px;
      color:#BBBBBB;
      a{
          color:#F5479F;
      }
  }
  .OfficialSite a:hover{
    color:#F5479F;
  }
  .downLoad{
      display: block;
      color:white;
      font-size: 12px;
      background-image: linear-gradient(#fc7dcd, #f7479e);
      width: 64px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 4px;
      margin-top: 10px;
  }
  .downLoad:hover{
    color:white;
  }
  .blindBox{
      div{
        width: 72%;
        line-height: 30px;  
      }
  }
  .arrowCircle{
      text-align: center;
      img{
          width: 30px;
          height: 30px;
      }
  }