@import "./global.scss";

.shengwuDetails {
    background: $grey;
    min-height: 100vh;
}

.list {
    padding: 16px 4%;

    a {
        background: $white;
        border-radius: 8px;
        display: flex;
        border: 1px solid #f5f5f5;
        color: black;
        padding: 12px 8px;
        margin-top: 8px;
        justify-content: space-between;
    .token_name{
        vertical-align: super;
        margin-left: 6px;
    }
        .left {
        }
        .right {
                display: block;
                width: 9px;
                height: 16px;
                background: url('../images/arrow.png') no-repeat 100% / cover;
                float: right;
                margin: 0 0 0 10px;
            }
    }
}
.pink{
    color: rgba(247, 71, 158, 1);
    display: inline-flex;
    align-items: center;
}
