@import './global.scss';

.infoBox {
    text-align: center;
    padding: 10px 8px;
    clear: both;
    background: linear-gradient(180deg, #211c1e 2%, #100b10 100%);
    box-shadow: -3px -3px 0px 0px #293036, 4px 4px 0px 0px #0e0d10;
    border-radius: 8px;
    border: 3px solid;
    border-image: linear-gradient(
            180deg,
            rgba(112, 123, 129, 1),
            rgba(23, 31, 42, 1)
        )
        3 3;
    .heading {
        color: $white;
        font-size: 13px;
        margin-bottom: 8px;
    }
    .number {
        color: $pink;
        font-size: 20px;
    }
    .listWrapper {
        overflow-x: scroll;
        width: 100%;
        &::-webkit-scrollbar {
            height: 0;
        }
    }
    .list {
        overflow-y: hidden;
        overflow-x: scroll;
        width: 432px;
        margin-bottom: 10px;
        li {
            float: left;
            text-align: center;
            color: #5b371e;
            width: 102px;
            height: 153px;
            background: url('../images/reward_bg.png') no-repeat 100% / cover;
            padding-top: 56px;
            margin-right: 6px;
        }
        .tokenName {
            font-size: 13px;
            margin-bottom: 16px;
        }
        .tokenNumber {
            font-size: 16px;
            color: $pink;
        }
    }
    .btn {
        background: url('../images/home_btn_bg.png') 100% / contain;
        color: $gold;
        width: 67px;
        height: 26px;
        font-size: 13px;
    }
}
