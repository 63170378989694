@import './global.scss';
.main{
  min-height: 100vh;
  background-color: black;
//   width: 100%;
//   display: flex;
//   align-items: center;
// position: relative;
}
.content{
    // position: absolute;
    padding-top:20%;
}

.imgCard img{
    width: 231px !important;
    height: 340px;
    display: block !important;
    margin: auto;
}
.selectMode1,.selectMode4{
width: 100%;
height: 13px;
}
.selectMode4{
    margin-bottom: 99px;
}
.selectMode2{
    width: 214px;
    height: 79px;
    margin:auto;
    margin-top: 9px;
    display: block;
}
.changeModeText{
    color:white;
    font-size: 14px;
    background-color: #1A1518;
    border-top:1px solid #293036;
    text-align: center;
    padding-top:6px;
    padding-bottom: 14px;
    position: relative;
    z-index: 2;
    margin-top: -20px;
}
.changeModeText img{
    width: 67px;
    height: 36px;
    margin-bottom: 8px;
}
.changeMode{
    padding-top:34px;
    margin: 0 8px;
    background-image:linear-gradient(#120D11, #17070A);
}