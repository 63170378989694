@import './global.scss';

.rules {
    min-height: 100vh;
    padding: 120px 18px 30px 18px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    max-width: 420px;

    .bg {
        background-color: rgba(0, 0, 0, 0.8);
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        z-index: -1;
    }

    .container {
        background: $black;
        opacity: 1;
        border: 2px solid #707b81;
        border-radius: 8px;
        padding: 16px 8px;
        z-index: 11;
        margin: 0 auto;
    }

    img {
        width: 100%;
        margin: 10px 0 0 0;
    }

    p {
        font-size: 14px;
        line-height: 24px;
        color: $white;
    }

    .title {
        color: $pink;
        font-size: 16px;
    }

    .subtitle {
        color: $gold;
        margin: 10px 0 5px 0;
        font-size: 16px;
        font-weight: 600;
    }

    span {
        color: gold;
    }

    .hint {
        font-size: 13px;
        margin-top: 10px;
    }
}
