@import './global.scss';

.menu {
    float: right;
    z-index: 99;
    position: relative;
    padding-right: 5%;
}
.icon {
    height: 40px;
    width: 40px;
    margin-top: 7px;
    background: url('../images/menu_icon.png') 100% / contain;
}
.dropdown {
    position: absolute;
    background: #1c1b22;
    color: red;
    top: 54px;
    right: 0;
    border: 1px solid #707b81;
    width: 246px;
    padding-bottom: 18px;
    li {
        color: #8d8d90;
        height: 48px;
        line-height: 48px;
        margin-top: 18px;
        cursor: pointer;
        &::before {
            content: '';
            display: inline-block;
            height: 30px;
            width: 30px;
            background-size: 100%;
            margin: 0 8px -9px 8px;
        }
        &.active {
            color: $pink;
            background: #27282c;
            border-right: 2px solid $pink;
        }
    }
    li:first-child::before {
        background-image: url('../images/menu_home.png');
    }
    li:nth-child(2)::before {
        background-image: url('../images/menu_market.png');
    }
    li:nth-child(3)::before {
        background-image: url('../images/menu_audit.png');
    }
    li:last-child::before {
        background-image: url('../images/menu_mine.png');
    }
    li.active {
        &:first-child::before {
            background-image: url('../images/menu_home_active.png');
        }
        &:nth-child(2)::before {
            background-image: url('../images/menu_market_active.png');
        }
        &:nth-child(3)::before {
            background-image: url('../images/menu_audit_active.png');
        }
        &:last-child::before {
            background-image: url('../images/menu_mine_active.png');
        }
    }
}
